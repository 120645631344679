.ListingHeaderMain {
  padding-bottom: 30px;
  /* border-bottom: 1px solid #eaecf0; */
  margin-bottom: 30px;
}
.ListingHeader {
  display: flex;
  justify-content: space-between;
}

.ListingHeader button {
  outline: none;
  border: none;
  color: #6941c6;
  font-size: 14px;
}

.leftMarginUser {
  margin-left: 24px;
  padding-top: 20px;
  @media (max-width: 600px) {
    margin-left: 0;
    margin-top: 20px;
  }
}

.Avatar {
  height: 160px !important;
  width: 160px !important;
  font-size: 42px !important;
  border: 4px solid #ffffff;
  margin-top: -21px;
  @media (max-width: 600px) {
    height: 130px !important;
    width: 130px !important;
  }
}
.alignHeader {
  display: flex;
  /* align-items: flex-end;
  margin-top: -21px; */
  width: 70%;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.ListerName {
  font-size: 30px;
}

.userName {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 10px;
  }
}

.userName > p {
  font-size: 30px;
}

.userName p {
  margin: 0;
}

.userDesc {
  color: #667085;
  font-size: 16px;
  margin: 0;
}

.badge {
  color: #6941c6;
  background-color: #f9f5ff;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 0 5px;
  font-size: 12px;
  @media (max-width: 600px) {
    margin-left: 0;
  }
}

.videoContainer {
  width: 75%;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.mediaContainer {
  display: flex;
  gap: 8px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.imgContainer {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.imgContainer .img {
  width: 48.5%;
  margin-right: 8px;
  display: inline-block;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
}

.imgContainer .img:nth-child(1n) {
  width: 100%;
  /* margin-top: 8px; */
}

.imgContainer .img:nth-child(2n) {
  margin-top: 8px;
  /* width: 48.5%; */
  margin: 0;
}

.imgContainer .img:nth-child(3n) {
  margin-right: 0px;
  margin-top: 8px;
  /* width: calc(48.5% + 4px); */
}

.imgContainer div img {
  width: 100%;
}

.buttonImage button:last-child {
  /* width: 80%; */
  padding: 5px 0;
}

.modalContentCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  width: 50%;
  max-height: 90%;
  overflow: auto;
  @media (max-width: 600px) {
    width: 90%;
  }
}


.modalContentVideo {
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  width: 50%;
  max-height: 60%;
  overflow: auto;
  height: 100%;
  @media (max-width: 600px) {
    width: 90%;
  }
}

.overLayImage{
  position: relative;
}

.overLayImage::after{
  content: '';
  background: rgba(0, 0, 0, .5);
  inset: 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.imgYoutubeButton{
  position: absolute;
  z-index: 2;
  width: 30% !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}