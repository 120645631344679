.selectContainer {
  display: flex;
  position: relative;
}

.selectContainer .endIcon {
  position: absolute;
  right: 14px;
  top: 8px;
}

.selectContainer .endIcon p {
  margin: 0;
}

.selectContainer select {
  appearance: none;
  background-image: none;
}


