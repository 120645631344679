.playerWrapper {
  position: relative;
}

.playerOverlay {
  position: absolute;
  inset: 0;
  background-color: transparent;
  display: flex;
  gap: 20px;
  padding: 30px;
  align-items: flex-end;
}

.playerOverlay svg {
  height: 13px;
  width: 13px;
  cursor: pointer;
}

.reactPlayer iframe{
  border-radius: 5px;
}