.comment {
  display: flex;
  align-items: flex-start;
  margin: 40px 0;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.userAvatar {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userAvatar .userImage {
  height: 64px;
  width: 64px;
}

.userAvatar .userName {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}

.userAvatar .userDate {
  font-size: 12px;
  line-height: 20px;
  color: #637381;
}

.userAvatar p {
  margin: 0;
}

.msg {
  width: 75%;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.msg p {
  font-size: 14px;
  margin: 8px 0;
}
