.serviceOfferedContainer {
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.serviceListContainer {
  width: 65%;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.serviceList {
  background-color: #f2f4f7;
  border-radius: 8px;
  padding: 16px;
  /* margin-right: 30px; */
}

.serviceList > p {
  font-size: 18px;
}

.listName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 25px;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
}

.listName li p,
.listName li svg {
  color: #667085;
  fill: transparent;
}

.listName li p {
  font-size: 14px;
  margin: 0;
}

.serviceListDetails > p {
  color: #667085;
  font-size: 18px;
  margin-bottom: 8px;
}

.serviceListDetails .desc {
  font-size: 16px;
  margin: 0;
  margin-bottom: 30px;
}

.styleTitle {
  color: #667085;
  font-size: 18px;
}

.styleContainer {
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.absolutePositionedBooking {
  @media (min-width: 768px) {
    position: absolute;
    transform: translateY(calc(440px + 100px));
    right: 121px;
    top: 0;
    width: calc(35% - 121px);
    z-index: 1;
  }
}

.bookingContainer {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding: 24px;
  /* width: 35%; */
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  background-color: white;

  @media (max-width: 600px) {
    margin-left: 0;
    width: 100%;
  }
}

.bookingTitle {
  color: #7f56d9;
  font-size: 30px;
}

.bookingDesc {
  color: #344054;
  font-size: 14px;
}

.bookingDates {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.bookingDates .button {
  text-align: center;
  background-color: transparent;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  width: 100%;
  margin: 10px 0;
  font-size: 14px;
}

.button.bookingButton {
  background-color: #7f56d9;
  color: white;
  padding: 12px 0;
}

.button.bookingButton.disabled {
  opacity: 0.5;
}

.button.contact {
  border: none;
  color: #6941c6;
}

.requestBooking {
  margin-top: auto;
}

.listBulletContainer {
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.ListStyleBullet {
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
}

.requestToBook {
  display: none;
  justify-content: space-between;
  background: white;
  position: fixed;
  z-index: 1111111;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 20px;
  align-items: center;
  border-top: 1px solid #eee;
  @media (max-width: 600px) {
    display: flex;
  }
}

.requestToBook button {
  padding: 12px !important;
  border: none;
}

.FormControlLabel {
  font-size: 14px !important;
}

.radioContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.perHour {
  font-size: 14px;
  color: #667085;
}

.containerService {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cancel {
  border: 1px solid #d0d5dd !important;
  border-radius: 8px;
  background-color: transparent;
  flex: 1;
  color: #344054 !important;
}

.apply {
  border: none !important;
  border-radius: 8px;
  background-color: #6941c6;
  flex: 1;
}

.applyContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
}

.dateCalendar {
  border: none !important;
  background-color: transparent !important;
}

.selectDateLabel {
  font-size: 16px;
  color: #667085;
  margin: 0;
}

.modalContentCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalContent {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  width: 50%;
  max-height: 90%;
  overflow: auto;
  @media (max-width: 600px) {
    width: 90%;
  }
}

.totalPrice {
  font-size: 48px;
  font-weight: normal;
  margin-bottom: 0;
  text-align: center;
}

.bookingCost {
  text-align: center;
  font-size: 14px;
  color: #667085;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.shareBtn {
  outline: none;
  border: none;
  color: #6941c6;
  font-size: 14px;
  margin-left: auto;
}

.shareContainer {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.favorite {
  font-size: 14px;
  color: #6941c6;
  font-weight: normal;
  margin-left: 10px;
}
