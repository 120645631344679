.web-share-fade {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center !important;
}

.music_btn_heading {
  color: #b2b2b2;
  margin-bottom: 15px;
  font-weight: 600;
}

.musicstyle_button {
  display: flex;
  margin-bottom: 0px;
  flex-wrap: wrap;
}

.music_btn_name {
  background: #9366ed;
  margin-right: 12px;
  border: 1px solid #8c52ff;
  border-radius: 20px;
  color: #fff;
  padding: 3px 20px;
  margin-bottom: 5px;
}