.Step1 {
  text-align: center;
}

.stepHeading {
  font-size: 30px;
  color: #101828;
}

.Avatar {
  height: 160px !important;
  width: 160px !important;
  font-size: 42px !important;
  border: 4px solid #ffffff;
  margin: auto;
  @media (max-width: 600px) {
    height: 130px !important;
    width: 130px !important;
  }
}

.button {
  width: 100%;
  border-radius: 8px;
  color: #344054;
  font-size: 16px;
  margin-bottom: 10px;
  border: 1px solid #d0d5dd;
  cursor: pointer;
  padding: 10px 0;
}

.button.action {
  background-color: #7f56d9;
  border: none;
  color: white;
}

.WizardSelectServiceContainer {
  display: flex;
  align-items: center;
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.WizardSelectService {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.addDate {
  width: fit-content;
  padding: 10px;
  float: right;
  margin-bottom: 30px;
}

.checkedService {
  display: flex;
  align-items: center;
}

.checkedService input {
  width: fit-content;
  margin-right: 10px;
}

p.checkedService input {
  width: inherit;
}

.perHourRate {
  color: #667085;
}

.progressContainer {
  height: 8px;
  width: 100%;
  background-color: #f4ebff;
  border-radius: 4px;
  margin-bottom: 20px;
}

.progress {
  background-color: #7f56d9;
  transition: all 0.7s ease-in-out;
  width: 20%;
  height: 100%;
  border-radius: inherit;
}

.textarea {
  width: 100%;
  resize: none;
  box-sizing: border-box;
  margin-bottom: 20px;
  height: 200px;
  @media (max-width: 600px) {
    padding: 10px;
  }
}

.displayFlex {
  display: flex;
  align-items: center;
  gap: 20px;
}

.displayFlex > div,
.flex1 {
  flex: 1;
}
