.uploadMediaContainer {
  position: relative;
}

.uploadButton {
  background-color: white;
  border-radius: 8px;
  font-size: 16px;
  border: none;
  padding: 15px ;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.uploadButton input {
  display: none;
}
