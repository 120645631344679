.MainListingContainer {
  padding: 20px 120px;
  position: relative;
  @media(min-width:1400px){
    max-width: 1280px;
    margin: auto;
    width: 100%;
  }
  @media (max-width: 600px) {
    padding: 20px;
  }
}

.listingForm {
  width: 50%;
  margin: auto;
  @media (max-width: 600px) {
    width: 100%;
  }
}
